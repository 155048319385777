// 이미지 생성 및 다운로드
const downloadImg = async (
  image,
  createFileName,
  { name = 'img', extension = 'png' } = {},
  isIOS,
) => {
  if (window.ReactNativeWebView) {
    // // React Native WebView 환경인 경우 메시지 전달
    // window.ReactNativeWebView.postMessage(
    //   JSON.stringify({
    //     type: 'downloadImage',
    //     data: image, // base64 형태의 이미지 데이터
    //     fileName: createFileName(extension, name),
    //   }),
    // );

    const chunkSize = 1024 * 1024; // 1MB
    const chunks = [];
    for (let i = 0; i < image.length; i += chunkSize) {
      chunks.push(image.slice(i, i + chunkSize));
    }

    for (let i = 0; i < chunks.length; i++) {
      const chunk = chunks[i];
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'downloadImageChunk',
          data: chunk,
          fileName: createFileName(extension, name),
          chunkIndex: i,
          totalChunks: chunks.length,
        }),
      );
    }
  } else if (window.Capacitor) {
    const { Capacitor } = window;
    const { Plugins } = Capacitor;
    const a = await Plugins.Filesystem.writeFile({
      data: image,
      path: name + '.' + extension,
      directory: 'CACHE',
    });
    const data = { path: a.uri, album: 'wecume' };

    if (Capacitor.getPlatform() === 'ios') {
      delete data.album;
    }

    await Plugins.MediaPlugin.savePhoto(data);
  } else {
    const a = document.createElement('a');
    a.href = image;

    if (!isIOS) {
      a.download = createFileName(extension, name);
    }

    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
  }
};

export default downloadImg;
